import React from "react"
import { Link } from "gatsby"
import { Seo, Layout, Section, Container } from "../components"

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="404: Not found" description="Page not found" slug="404" />
      <Section padding="lg">
        <Container
          padding="sm"
          classes="bg-white sm:max-w-lg lg:max-w-2xl mx-4 sm:mx-auto p-4 sm:p-8 md:p-16 lg:p-24 text-center space-y-4"
        >
          <h1>404: Not Found</h1>
          <p>
            This URL does not exist...please return to our{" "}
            <Link
              to="/"
              className="text-accent-light hover:text-accent font-semibold"
            >
              homepage
            </Link>
          </p>
        </Container>
      </Section>
    </Layout>
  )
}

export default NotFoundPage
